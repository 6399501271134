html {
    height: 100%;
}

body {
    height: 100%;
}

.background {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #efefef; */
    flex-direction: column;
    z-index: -2;
}

.logo {
    margin-bottom: 32px;
    width: 100px;
    height: auto;
    z-index: -2;
}

.or {
    margin: 16px 0;
    text-align: center;
    font-size: 10px;
}

.box {
    box-shadow: 0 0 36px rgba(0,0,0,0.15);
    z-index: -2;
    /* width: 400px; */
    border-radius: 10px;
    background: white;
    padding: 32px;
    display: flex;
    flex-direction: column;
}

.box h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: grey;
}

.fbButton {
    width: 100%;
    height: 30px;
    margin: 0;
    margin-bottom: 8;
    border-radius: 20;
}

.box > div {
    margin-bottom: 16px;
}

.buttons {
    margin-top: 16px;
}