.background {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #efefef; */
    flex-direction: column;
    padding: 32px;
}

h3 {
    margin-bottom: 32px;
}

.box {
    box-shadow: 0 0 36px rgba(0,0,0,0.15);
    width: 500px;
    border-radius: 10px;
    background: white;
    display: flex;
    flex-direction: column;
}

.box > div {
    margin-bottom: 16px;
}

.logout {
    position: fixed;
    right: 16px;
    top: 16px;
}

