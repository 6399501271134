.form h3 {
    font-size: 20px;
}

.form {
    flex-direction: column;
    flex: 1;
    display: flex;
}

.section {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
}

.section > label {
    margin-bottom: 5px;
    font-size:14px;
}

.textInput {
    text-indent: 5px;
    height: 40px;
    border-radius: 5px !important;
    border: 1px solid silver !important;
}

.newClient {
    margin-right: 8px;
    font-size: 14px;
}

.space {
    flex: 1;
}

.submit {
    padding: 16px;
    border: none;
    background-color: var(--main-color);
    color: white;
    font-weight: bold;
    border-radius: 10px;
}